import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexCCLogo from '../../assets/svgs/webex-cc-logo.svg'
import Integration from '../../components/Integration'
import { PAGE_VIEWED } from '../../constants/metrics'
import { IIntegration } from '../../interfaces/integrationInterfaces'
import httpService from '../../services/httpService'
import { logError } from '../../services/loggerService'
import MetricsService from '../../services/metricsService'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
interface IEditIntegrationProps {
  clientId: string
  clientSecret?: string
  setHasError: Function
}
const EditIntegration: React.FC<IEditIntegrationProps> = ({
  clientId,
  clientSecret,
  setHasError,
}) => {
  const token = useSelector((state: AppState) => retrieveToken(state))
  const [dataFetched, setDataFetched] = useState<IIntegration | null>(null)
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
  const [integrationName, setIntegrationName] = useState<string>('')
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: 'Edit Integrations',
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const headers = {
        Authorization: `Bearer ${token}`,
      }

      try {
        const httpResponse = await httpService.get(
          `${process.env.GATSBY_BFF_BASE_URL}v1/integrations/${clientId}`,
          { headers }
        )
        setDataFetched({
          ...httpResponse.data,
          ...(clientSecret && { secret: clientSecret }),
        })
        setIntegrationName(httpResponse.data.name)
        setShowLoadingScreen(false)
      } catch (err) {
        setShowLoadingScreen(false)
        setHasError(true)
        logError(`An error has occurred while fetching the integration ${err}`)
      }
    }

    fetchData()
  }, [clientId, clientSecret, setHasError, token])

  const updateIntegrationName = (name: string): void => {
    setIntegrationName(name)
  }
  return (
    <section className="edit-integration">
      {clientSecret ? (
        <div className="success-header">
          <h1>{t('integrations.createSuccessHeader')} &#x1f389;</h1>
          <h4>{t('integrations.createSuccessSub')}</h4>
        </div>
      ) : null}
      <div>
        <h2>{integrationName}</h2>
      </div>
      {showLoadingScreen ? (
        <div className="loading-screen">
          <WebexCCLogo className="loading-stuff" />
        </div>
      ) : null}
      {dataFetched && !showLoadingScreen ? (
        <Integration
          integration={dataFetched}
          updateName={updateIntegrationName}
        />
      ) : null}
    </section>
  )
}

export default EditIntegration
