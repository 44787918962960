import { RouteComponentProps, Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InternalServerError from '../components/InternalServerError'
import BasicLayout from '../layouts/BasicLayout'
import { retrieveUser } from '../state/auth'
import { AppState } from '../state/store'
import IntegrationList from '../templates/integrations'
import CreateIntegration from '../templates/integrations/create'
import EditIntegration from '../templates/integrations/edit'
import { login } from '../utils/auth.utils'

const RouterPage = (
  props: { component: JSX.Element } & RouteComponentProps
): JSX.Element => {
  return props.component
}

const App: React.FC<RouteComponentProps> = (props: any) => {
  const user = useSelector((state: AppState) => retrieveUser(state))
  const [showPage, setShowPage] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (!Object.keys(user).length) {
      login()
    } else {
      setShowPage(true)
    }
  }, [user])

  return (
    <>
      {showPage && !hasError ? (
        <BasicLayout>
          <Router className="below-header-container">
            <RouterPage
              path={withPrefix('/my-apps/create')}
              component={
                <CreateIntegration
                  hasCurrentIntegrations={
                    props.location.state?.hasCurrentIntegrations
                  }
                />
              }
            />
            <RouterPage
              path={withPrefix('/my-apps/:appId')}
              component={
                <EditIntegration
                  clientId={props.location.state?.clientId}
                  clientSecret={props.location.state?.clientSecret}
                  setHasError={setHasError}
                />
              }
            />
            <RouterPage
              path={withPrefix('/my-apps')}
              component={<IntegrationList setHasError={setHasError} />}
            />
          </Router>
        </BasicLayout>
      ) : null}
      {showPage && hasError ? <InternalServerError /> : null}
    </>
  )
}

export default App
