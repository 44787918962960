import { Button, List } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexCCLogo from '../../assets/svgs/webex-cc-logo.svg'
import IntegrationListItem from '../../components/IntegrationListItem'
import { PAGE_VIEWED } from '../../constants/metrics'
import { IIntegration } from '../../interfaces/integrationInterfaces'
import httpService from '../../services/httpService'
import { logError } from '../../services/loggerService'
import MetricsService from '../../services/metricsService'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'

interface IIntegrationListProps {
  setHasError: Function
}
const IntegrationList: React.FC<IIntegrationListProps> = ({ setHasError }) => {
  const { t } = useTranslation()
  const token = useSelector((state: AppState) => retrieveToken(state))

  const [dataFetched, setDataFetched] = useState<any>(null)

  const routeToCreatePage = (empty: boolean): void => {
    if (empty) {
      navigate('/my-apps/create')
    } else {
      navigate('/my-apps/create', { state: { hasCurrentIntegrations: true } })
    }
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const headers = {
        Authorization: `Bearer ${token}`,
      }

      try {
        const httpResponse = await httpService.get(
          `${process.env.GATSBY_BFF_BASE_URL}v1/integrations`,
          { headers }
        )

        if (httpResponse.data.total === 0) {
          routeToCreatePage(true)
        } else {
          setDataFetched(httpResponse.data)
        }
      } catch (err) {
        setHasError(true)
        logError(`An error has occurred while fetching integrations ${err}`)
      }
    }

    fetchData()
  }, [setHasError, token])

  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    subPageName: 'List Integrations',
  })

  return (
    <>
      {dataFetched?.total > 0 ? (
        <section className="integrations">
          <div className="integrations-row">
            <h2>{t('integrations.myApps')}</h2>
            <Button
              className="md-button md-button--blue"
              onClick={(): void => {
                routeToCreatePage(false)
              }}
              // TODO: Add tooltip to let user know why this is disabled
              disabled={dataFetched?.total >= 10}
              data-cy="create-integration-btn"
            >
              {t('integrations.createButtonText')}
            </Button>
          </div>
          <div className="integrations-row">
            {dataFetched?.data?.length ? (
              <List id="integrations-list" data-cy="integrations-list">
                {(dataFetched?.data as IIntegration[]).map(
                  (data: IIntegration, i: number) => {
                    return (
                      <IntegrationListItem
                        key={i}
                        name={data.name}
                        id={data.id}
                        registrationDate={data.registrationDate}
                      />
                    )
                  }
                )}
              </List>
            ) : null}
          </div>
        </section>
      ) : (
        <div className="loading-screen">
          <WebexCCLogo className="loading-stuff" />
        </div>
      )}
    </>
  )
}

export default IntegrationList
