export const INTEGRATION_NAME_VALIDATOR = {
  pattern: /^\w+( +\w+)*$/,
  message: 'integrations.integrationNameValidationMessage',
  length: 45,
}
export const INTEGRATION_DESCRIPTION_VALIDATOR = {
  pattern: /[\S]/,
  message: 'integrations.descriptionValidationMessage',
  length: 1024,
}
export const INTEGRATION_URL_VALIDATOR = {
  pattern: /(^[\S]*[a-zA-Z]+[\w]*:\/{2}[\S]+$)/,
  message: 'integrations.redirectUrisValidationMessage',
  length: 2048,
}
